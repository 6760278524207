import {IconButton, InputAdornment, TextField} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, {FC, useRef} from 'react'

type Props = {
  onChangeText: (text: string) => void
  value?: string
  label?: string
}

const MyTextField: FC<Props> = ({onChangeText, value, label}) => {
  const inputRef = useRef<HTMLInputElement>()
  return (
    <TextField
      label={label}
      fullWidth
      inputRef={inputRef}
      variant="outlined"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeText(event.target.value)
      }}
      value={value ?? ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                onChangeText('')
                inputRef?.current?.focus()
              }}
              edge="end">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default MyTextField
