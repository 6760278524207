import React, {FC, useEffect} from 'react'
import Memes, {IMeme} from '../../../Data/Memes'
import Thumb from './Thumb'
import {Grid, Stack} from '@mui/material'

type Props = {
  children?: React.ReactNode
  onSelect: (id: number) => void
}

const MemePicker: FC<Props> = ({children, onSelect}) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     onSelectMeme(Memes[0])
  //   }, 100)
  // }, [null])

  const renderMemeThumbs = () => {
    return Memes.map((meme, key) => (
      <Grid item xs={4} md={2} key={key}>
        <Thumb
          meme={meme}
          onClick={() => {
            //onSelectMeme(meme)
            onSelect(key)
          }}
        />
      </Grid>
    ))
  }

  return (
    <Grid container spacing={0}>
      {renderMemeThumbs()}
    </Grid>
  )

  // return (
  //   <Stack overflow="auto" direction={'row'}>
  //     {renderMemeThumbs()}
  //   </Stack>
  // )
}

export default MemePicker
