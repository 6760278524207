import CssBaseline from '@mui/material/CssBaseline'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import './App.css'
import Layout from './Screens/Layout/Layout'
import Main from './Screens/Main/Main'
import SelectMeme from './Screens/SelectMeme/SelectMeme'

const defaultTheme = createTheme()
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<SelectMeme />} />
      <Route path="meme/:id" element={<Main />} />

      <Route path="*" element={<>404</>} />
    </Route>,
  ),
)

function App() {
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  )
}

export default App
