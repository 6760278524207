import {Container} from '@mui/material'
import React, {FC} from 'react'
import {Outlet} from 'react-router-dom'

type Props = {children?: React.ReactNode}

const Layout: FC<Props> = ({children}) => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default Layout
