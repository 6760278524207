import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import React, {FC, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import Memes, {IMeme} from '../../Data/Memes'
import MemeDisplay from './Components/MemeDisplay'
import MyTextField from '../../Components/MyTextField'

type Props = {children?: React.ReactNode}

const Main: FC<Props> = ({children}) => {
  let {id} = useParams<'id'>()

  useEffect(() => {
    if (id !== undefined) {
      const meme = Memes[parseInt(id)]

      setMeme(meme)
      setUserTexts(meme.textDefinitions.map(x => x.defaultText))
    }
  }, [id])

  const [meme, setMeme] = useState<IMeme | undefined>(undefined)
  const [userTexts, setUserTexts] = useState<string[]>([])
  const myRef = useRef<SVGSVGElement | null>(null)

  const convertSvgToImage = function () {
    const svg = myRef
    if (svg.current !== null) {
      let svgData = new XMLSerializer().serializeToString(svg.current)

      const canvas = document.createElement('canvas')
      canvas.setAttribute('id', 'canvas')
      const svgSize = svg.current?.getBoundingClientRect()
      canvas.width = svgSize.width
      canvas.height = svgSize.height
      const img = new Image()
      img.setAttribute(
        'src',
        'data:image/svg+xml;base64,' +
          btoa(unescape(encodeURIComponent(svgData))),
      )

      img.onload = function () {
        const context = canvas.getContext('2d')
        if (context !== null) {
          context.drawImage(img, 0, 0)
          const canvasdata = canvas.toDataURL('image/png')
          const a = document.createElement('a')
          const now = new Date()
          const dateText = `${now.getHours()}${now.getMinutes()}${now.getSeconds()}`
          a.download = `meme_${dateText}.png`
          a.href = canvasdata
          document.body.appendChild(a)
          a.click()
        }
      }
    }
  }

  if (meme === undefined) {
    return null
  }

  const renderTextFields = () => {
    return meme.textDefinitions.map((textDefinition, key) => {
      const userText = userTexts[key] ?? textDefinition.defaultText
      return (
        <MyTextField
          label="Text"
          key={key}
          onChangeText={text => {
            userTexts[key] = text
            setUserTexts([...userTexts])
          }}
          value={userText}
        />
      )
    })
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Breadcrumbs aria-label="breadcrumb">
            <Button href="/" startIcon={<ChevronLeftIcon />}>
              Select Meme 2
            </Button>
            <Typography color="text.primary">Meme</Typography>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2} marginTop={0.5}>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                backgroundColor: 'lightgrey',
              }}
              justifyContent="center"
              alignItems="center"
              display="flex">
              <MemeDisplay
                meme={meme}
                userTexts={userTexts}
                // topText={topText}
                //bottomText={bottomText}
                myRef={myRef}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack spacing={2} padding={0}>
              {renderTextFields()}

              <Button
                variant="contained"
                size="large"
                onClick={() => convertSvgToImage()}>
                Opslaan
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Main
