import React, {FC} from 'react'
import MemePicker from '../Main/Components/MemePicker'
import {Link, useNavigate} from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  Breadcrumbs,
  Typography,
  Grid,
  Container,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

type Props = {children?: React.ReactNode}

const SelectMeme: FC<Props> = ({children}) => {
  const navigate = useNavigate()
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2}}>
            <MenuIcon />
          </IconButton> */}
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">Select Meme</Typography>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="lg">
        <Grid container spacing={2} marginTop={0.5}>
          <Grid item xs={12}>
            <MemePicker
              onSelect={id => {
                navigate(`/meme/${id}`)
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default SelectMeme
