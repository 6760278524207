import {CSSProperties} from 'react'

export type IMeme = {
  image: any
  textDefinitions: ITextDef[]
}

export type IMemeDisplay = {meme: IMeme; ratio: number}
export type IMediaValue = {
  mobile: number
  desktop: number
}
export type ITextDef = {
  xOffset: number //perc, 0.5 = 50%
  yOffset: number
  lineHeight: number
  maxCharacters: IMediaValue
  fontSize: IMediaValue
  defaultText: string
  fontStyle: CSSProperties
  //transform?: string
}

const defaultTopTextDef: ITextDef = {
  maxCharacters: {mobile: 40, desktop: 40},
  fontSize: {mobile: 18, desktop: 25},
  xOffset: 0.5,
  yOffset: 0.1,
  lineHeight: 30,
  defaultText: '',
  fontStyle: {
    fontFamily: 'Impact',
    fill: '#FFF',
    stroke: '#000',
    textTransform: 'uppercase',
  },
}

const defaultBottomTextDef: ITextDef = {
  ...defaultTopTextDef,
  yOffset: 1,
}

/*
distracted:
new thing
me
jealous old thing

*/

const memes: IMeme[] = [
  {
    image: require('../Assets/Memes/onedoes2.png'),
    textDefinitions: [
      {...defaultTopTextDef, defaultText: 'One does not simply'},
      {
        ...defaultBottomTextDef,
        defaultText: 'Unsee this meme when watching lotr',
      },
    ],
  },
  {
    image: require('../Assets/Memes/Button.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        defaultText: 'Push code update on Friday, not untested',
        maxCharacters: {desktop: 18, mobile: 15},
        fontSize: {desktop: 16, mobile: 18},
        xOffset: 0.19,
        yOffset: 0.14,
        lineHeight: 20,
        fontStyle: {
          ...defaultTopTextDef.fontStyle,
          rotate: '-12deg',
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
      },
      {
        ...defaultBottomTextDef,
        defaultText: 'Push code update monday morning, fully tested',
        maxCharacters: {desktop: 15, mobile: 15},
        fontSize: {desktop: 14, mobile: 18},
        xOffset: 0.53,
        yOffset: 0.14,
        lineHeight: 20,
        fontStyle: {
          ...defaultTopTextDef.fontStyle,
          rotate: '-12deg',
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
      },
      {
        ...defaultBottomTextDef,
        defaultText: 'Crowdstrike',
        //maxCharacters: {desktop: 15, mobile: 15},
        fontSize: {desktop: 36, mobile: 36},
        xOffset: 0.5,
        yOffset: 0.8,
        lineHeight: 20,
        fontStyle: {
          ...defaultTopTextDef.fontStyle,
        },
      },
    ],
  },
  {
    image: require('../Assets/Memes/distracted.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        xOffset: 0.25,
        yOffset: 1,
        maxCharacters: {desktop: 20, mobile: 20},
        defaultText: 'Veel te lang series kijken op netflix',
      },
      {...defaultTopTextDef, xOffset: 0.65, yOffset: 0.5, defaultText: 'ikke'},
      {
        ...defaultBottomTextDef,
        xOffset: 0.88,
        yOffset: 0.7,
        maxCharacters: {desktop: 10, mobile: 10},
        defaultText: 'Op tijd naar bed gaan',
      },
    ],
  },
  {
    image: require('../Assets/Memes/sad1.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        yOffset: 0.4,
        defaultText: 'After you finish a series on netflix',
      },
      {
        ...defaultBottomTextDef,
        defaultText: "and don't know what to do with your life",
      },
    ],
  },
  {
    image: require('../Assets/Memes/carl2.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        xOffset: 0.6,
        yOffset: 0.1,
        fontSize: {mobile: 20, desktop: 30},
        maxCharacters: {desktop: 20, mobile: 20},
        defaultText: 'You know how grandpa died?',
      },
      {
        ...defaultTopTextDef,
        fontSize: {mobile: 20, desktop: 30},
        maxCharacters: {mobile: 25, desktop: 18},
        xOffset: 0.7,
        yOffset: 0.35,
        defaultText: 'He fell down a well Carl',
      },
      {
        ...defaultBottomTextDef,
        xOffset: 0.55,
        yOffset: 0.65,
        fontSize: {mobile: 20, desktop: 40},
        maxCharacters: {desktop: 10, mobile: 10},
        defaultText: 'Dad stop',
      },
      {
        ...defaultBottomTextDef,
        xOffset: 0.55,
        yOffset: 0.95,
        fontSize: {mobile: 20, desktop: 35},
        maxCharacters: {mobile: 20, desktop: 20},
        defaultText: "He couldn't see that well carl!",
      },
    ],
  },
  {
    image: require('../Assets/Memes/obama.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        maxCharacters: {desktop: 35, mobile: 25},
        xOffset: 0.5,
        fontStyle: {
          ...defaultTopTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText: 'Me liking my own meme creator I just build.',
      },
    ],
  },
  {
    image: require('../Assets/Memes/anakin.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        xOffset: 0.25,
        yOffset: 0.4,
        maxCharacters: {desktop: 20, mobile: 20},
        defaultText: 'I am making a meme',
      },
      {
        ...defaultTopTextDef,
        xOffset: 0.75,
        yOffset: 0.37,
        maxCharacters: {desktop: 25, mobile: 20},
        defaultText: "You're gonna put text in the third panel right?",
      },
      {
        ...defaultTopTextDef,
        xOffset: 0.75,
        yOffset: 1,
        defaultText: 'right?!',
      },
    ],
  },
  {
    image: require('../Assets/Memes/gru4.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        maxCharacters: {desktop: 13, mobile: 10},
        xOffset: 0.38,
        yOffset: 0.1,
        lineHeight: 17,
        fontSize: {desktop: 13, mobile: 14},
        fontStyle: {
          ...defaultTopTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText: 'Find a bug in your code',
      },
      {
        ...defaultTopTextDef,
        maxCharacters: {desktop: 13, mobile: 10},
        xOffset: 0.88,
        yOffset: 0.1,
        lineHeight: 17,
        fontSize: {desktop: 13, mobile: 14},
        fontStyle: {
          ...defaultTopTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText: 'Try to find the bug with console.log',
      },
      {
        ...defaultBottomTextDef,
        maxCharacters: {desktop: 13, mobile: 10},
        xOffset: 0.38,
        yOffset: 1,
        lineHeight: 17,
        fontSize: {desktop: 13, mobile: 14},
        fontStyle: {
          ...defaultBottomTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText: 'The bug is gone',
      },
      {
        ...defaultBottomTextDef,
        maxCharacters: {desktop: 13, mobile: 10},
        xOffset: 0.88,
        yOffset: 1,
        lineHeight: 17,
        fontSize: {desktop: 13, mobile: 14},
        fontStyle: {
          ...defaultBottomTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText: 'The bug is gone',
      },
    ],
  },
  {
    image: require('../Assets/Memes/mydrake.png'),
    textDefinitions: [
      {
        ...defaultTopTextDef,
        maxCharacters: {desktop: 17, mobile: 17},
        xOffset: 0.75,
        fontStyle: {
          ...defaultTopTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText: 'Just use photoshop to create the image',
      },
      {
        ...defaultBottomTextDef,
        maxCharacters: {desktop: 17, mobile: 17},
        xOffset: 0.75,
        yOffset: 0.85,
        fontStyle: {
          ...defaultBottomTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText: 'Use AI the generate your own meme',
      },
    ],
  },
  {
    image: require('../Assets/Memes/leo_point.png'),
    textDefinitions: [
      {...defaultTopTextDef, defaultText: 'When you see the same guy'},
      {
        ...defaultBottomTextDef,
        defaultText: 'In another movie',
      },
    ],
  },
  {
    image: require('../Assets/Memes/condensending.jpg'),
    textDefinitions: [
      {...defaultTopTextDef, defaultText: 'So tell me again'},
      {
        ...defaultBottomTextDef,
        defaultText: 'Why is this not funny',
      },
    ],
  },
  {
    image: require('../Assets/Memes/think1.png'),
    textDefinitions: [
      {...defaultTopTextDef, defaultText: "Can't have school shootings"},
      {
        ...defaultBottomTextDef,
        defaultText: "if you don't have schools",
      },
    ],
  },
  {
    image: require('../Assets/Memes/norman_ikke.png'),
    textDefinitions: [
      {...defaultTopTextDef, defaultText: 'Me when creating AI art'},
      {
        ...defaultBottomTextDef,
        defaultText: "You know, I'm something of an artist myself",
      },
    ],
  },
  {
    image: require('../Assets/Memes/leo_smile2.jpg'),
    textDefinitions: [
      {...defaultTopTextDef, yOffset: 0.05, defaultText: 'My face when I'},
      {
        ...defaultBottomTextDef,
        defaultText: 'am teabagging my opponent',
      },
    ],
  },

  {
    image: require('../Assets/Memes/change1.png'),
    textDefinitions: [
      {
        ...defaultBottomTextDef,
        maxCharacters: {desktop: 25, mobile: 25},
        xOffset: 0.57,
        yOffset: 0.8,
        fontSize: {mobile: 14, desktop: 20},
        fontStyle: {
          ...defaultBottomTextDef.fontStyle,
          fontFamily: 'Arial',
          fill: '#000',
          stroke: 'none',
        },
        defaultText:
          'Ananas hoort niet op een pizza en als je dat wel denkt ben je gek',
      },
    ],
  },
]

export default memes
