import React, {FC} from 'react'
import {IMeme} from '../../../Data/Memes'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'

type Props = {
  meme: IMeme
  onClick: () => void
}

const ImageButton = styled(ButtonBase)(({theme}) => ({
  position: 'relative',
  height: 150,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}))

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
})

const ImageBackdrop = styled('span')(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.3,
  transition: theme.transitions.create('opacity'),
}))

const Thumb: FC<Props> = ({meme, onClick}) => {
  return (
    <ImageButton
      focusRipple
      onClick={() => {
        onClick()
      }}
      style={{
        width: '100%',
      }}>
      <ImageSrc style={{backgroundImage: `url(${meme.image})`}} />
      <ImageBackdrop className="MuiImageBackdrop-root" />
    </ImageButton>
  )

  // return (
  //   <img
  //     src={meme.image}
  //     style={{height: 150}}
  //     onClick={() => {
  //       onClick()
  //     }}
  //     alt="meme"
  //     title="meme"
  //   />
  // )
}

export default Thumb
